module.exports = {
  siteTitle: "EconTechie - A blog about tech, economics & society",
  shortSiteTitle:
    "EconTechie - Joining the dots between society and technology. A blog by Dami Payne.",
  siteDescription:
    "EconTechie - Joining the dots between society and technology. A blog by Dami Payne.",
  siteUrl: "https://www.EconTechie.com",
  pathPrefix: "",
  siteImage: "https://www.EconTechie.com/preview.jpg",
  siteLanguage: "en",

  /* author */
  authorName: "Damilola Payne",
  authorTwitterAccount: "@damiPayne",

  /* info */
  headerTitle: "EconTechie",
  headerSubTitle: "Joining the dots between society and technology",

  /* manifest.json */
  manifestName:
    "EconTechie - Joining the dots between society and technology. A blog by Dami Payne.",
  manifestShortName: "EconTechie", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#99D6F8",
  manifestDisplay: "standalone",

  gravatarImgMd5: "",

  // social
  authorSocialLinks: [
    { name: "github", url: "https://github.com/DamiPayne" },
    { name: "twitter", url: "https://twitter.com/damiPayne" },
    { name: "linkedin", url: "https://uk.linkedin.com/in/damilolapayne" }
  ]
};
